import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaviconsService } from '@zonar-ui/core';
import { environment } from '@env/environment';
import { permissions } from '@env/shared';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ], providers: [
    FaviconsService,
    // TODO: Build out Auth0 application in dev, QA, and Prod tenants.
    // TODO: Onboard to Core API for your new application to be able to build permissions.

    // Symbols required for authentication
    {
      provide: 'appUrl', useValue: environment.appUrl
    },
    {provide: 'audience', useValue: environment.auth.audience},
    {provide: 'clientID', useValue: environment.auth.clientID},
    {provide: 'domain', useValue: environment.auth.domain},
    {provide: 'applicationId', useValue: environment.auth.applicationId},
    {provide: 'environment', useValue: environment.authEnv},
    {provide: 'useRefreshTokens', useValue: environment.auth.useRefreshTokens},
    // Symbols required for permissions.
    {provide: 'defaultZonarRole', useValue: environment.auth.defaultZonarRole},
    {provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
    {provide: 'logoutReturnToUrl', useValue: window.location.origin},
    {provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},

    // ZonarUiAuthModule,
  ]
})
export class CoreModule { }
