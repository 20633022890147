import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { accountInitialState } from './user.reducers';
import { reducer as userReducer } from './user.reducers';
import {environment} from '../../../../environments/environment';


export interface State {
  user: any;
}

export const initialState = {
  user: {
    account: '',
    availableAccounts: [],
    dispatcherEntityId: undefined
  }
};

export const reducers: ActionReducerMap<State> = {
  user: userReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
