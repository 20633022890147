import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { environment } from '@env/environment';
import { PermissionsService } from '@zonar-ui/auth';
// import { Company } from '@zonar-ui/auth/lib/models/core-company-api.model';
// import { User, UserProfile } from '@zonar-ui/auth/lib/models/core-user-api.model';
import {combineLatest, forkJoin, iif, Observable, of, throwError, zip} from 'rxjs';
import {catchError, delay, filter, finalize, map, mergeMap, switchMap, take} from 'rxjs/operators';
import { AppService } from './app.service';
import {DataDogService} from '@app/modules/services/datadog.service';
import {Company} from '@zonar-ui/sidenav/lib/models/company.model';
import {IUser} from '@zonar-ui/auth/lib/models/user.model';
import {IUserProfile} from '@zonar-ui/auth/lib/models/user-profile.model';

import {ICompany} from '@zonar-ui/auth/lib/models/company.model';
import {ChangeCompanyService} from '@zonar-ui/sidenav';
import {IUserGroupPolicy} from '@zonar-ui/auth/lib/models/user-group-policy.model';

export interface AppRouteData {
    activeAccount: string;
    userProfiles?: IUserProfile[];
    user?: IUser;
    selectedCompany?: Company;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<AppRouteData>> {
    re = new RegExp(environment.swiftAccRegexCheck);

    constructor(
        private appService: AppService,
        private permissionsService: PermissionsService,
        private router: Router,
        private datadogService: DataDogService,
        private activatedRoute: ActivatedRoute,
        private changeCompanyService: ChangeCompanyService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppRouteData> {
        let activeAccount = route.queryParamMap.get('activeAccount');
        this.appService.isAppDataLoading$.next(true);

        //regex check here based on the environment - qaswi...., devswi...., etc. to update activeAccount
        if(this.re.test(activeAccount)) {
            activeAccount = environment.swiftAccToChangeTo;
        }
        const index = 0;
        if (activeAccount) {
            return this.permissionsService.getIsZonarUser().pipe(
                switchMap((isZonarUser) => {
                    if (isZonarUser) {
                        return this.zonarUserLoginLogic(activeAccount);
                    } else {
                        return this.appService.getCompanyByAccount(activeAccount).pipe(
                            switchMap((company) => {
                                if (!company || company.length < 1) {
                                    const err = {
                                        name: 'Unable to retrieve company information',
                                        message: 'User was unable to retrieve company information',
                                        status: 400
                                    };
                                    return throwError(err);
                                }
                                return of(company);
                            }),
                            switchMap((company) => this.getCompanyLoginMode(company[0].id)),
                            mergeMap(companies =>
                                zip(...companies.map(company =>
                                    this.appService.getLegacyAccountCode(company.id).pipe(
                                        map(accounts => ({
                                            ...company,
                                            legacyAccounts: accounts
                                        })), //adds legacy accounts to login methods
                                        catchError((err) => {
                                            console.log('failed to retrieve legacy account codes', err);
                                            const errorMessage = {
                                                name: 'Unable to retrieve legacy accounts',
                                                message: '',
                                                status: 400
                                            };
                                            return this.handleError(errorMessage, '');
                                        })
                                    )))
                            ),
                                switchMap((companyLoginWithLegacyAccounts) =>
                                    this.determineLegacyAccounts(activeAccount, companyLoginWithLegacyAccounts)),
                                switchMap((company) => this.determineLoginMode(company)),
                                switchMap((userPermissionsInfo) => forkJoin([
                                    of(userPermissionsInfo),
                                    this.permissionsService.getUser().pipe(take(1), map(users => users)),
                                    this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => company)),
                                ])),
                                map(([userPermissionsInfo, user, company]) =>
                                    this.mappingResults(activeAccount, userPermissionsInfo as any, user, company)));
                    }
                }),
                catchError(err => this.handleError(err, activeAccount)),
                finalize(() => this.appService.isAppDataLoading$.next(false))
                );
        }
        return this.permissionsService.getIsZonarUser().pipe(
            switchMap((isZonarUser) => {
                if (isZonarUser) {
                    return this.zonarUserLoginLogic(activeAccount);
                } else {
                    //from sidenav
                    return this.permissionsService.getCurrentCompanyContext().pipe(
                        filter((x) => !!x),
                        switchMap((c) => this.getCompanyLoginMode(c.id)),
                        switchMap((company) => this.determineLoginMode(company[index])),
                        switchMap((userPermissionsInfo) => forkJoin([
                            of(userPermissionsInfo),
                            this.permissionsService.getUser().pipe(take(1), map(users => users)),
                            this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => company))
                        ])),
                        map(([userPermissionsInfo, user, company]) =>
                            this.mappingResults(activeAccount, userPermissionsInfo as any, user, company)),
                    );
                }
            }
        ),
            catchError(err => this.handleError(err, activeAccount)),
            finalize(() => this.appService.isAppDataLoading$.next(false))
        );
    }

    private mapToAppRouteData(activeAccount: string, userProfiles: any[], user: IUser, selectedCompany: any): AppRouteData {
        return {
            activeAccount,
            userProfiles,
            user,
            selectedCompany
        };
    }

    private getUserProfiles(profiles: IUserProfile[], companyId: string): IUserProfile[] {
        let userProfile = profiles.filter((c: any) => c.applicationId === environment.auth.applicationId);
        if(userProfile.length > 1) {
            const uniqueCompanyProfiles: IUserProfile[] = [];
            userProfile.forEach(p => {
                if(uniqueCompanyProfiles.filter(u => u.companyId === p.companyId).length === 0) {
                    uniqueCompanyProfiles.push(p);
                }
            });
            userProfile = uniqueCompanyProfiles.filter((up) => up.companyId === companyId);
        }
        if (!userProfile || userProfile.length === 0) {
            throw new Error('No userprofile found');
        }
        const prevSelection = this.appService.getPrevCompanySelection();
        if(prevSelection && Object.keys(prevSelection).length > 0) {
            const filteredProfiles = userProfile.filter((u: IUserProfile) => (u.companyId === prevSelection.id &&
                u.userId === prevSelection.userId));
            if(filteredProfiles.length > 0) {
                userProfile = filteredProfiles;
            }
        }
        return userProfile;
    }

    private getPolicyForApplication(userRole: IUserGroupPolicy | IUserProfile): {roles: any[]; application?: any} {
         let eldxPerms;
         if (userRole && 'policy' in userRole && userRole.policy) {
             eldxPerms = userRole.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId)[0];
         } else {
             if ('roles' in userRole) {
                 eldxPerms = [];
                 eldxPerms.push({roles: userRole.roles});
             }
         }
        return eldxPerms;

    }

    private handleError(err: any, account: string): Observable<AppRouteData> {
        this.datadogService.addRumError(err);
        // If there is any error route to error page
        const pnfData = {
            title: 'UDL',
            error: err.name,
            errorMessage: err.message,
            status: err.status,
            url: environment.appUrl,
        };
        this.permissionsService.getUser().subscribe(x => {
            if (x === undefined || x === null) {
                const errorText = 'User returned from Entity /user api is empty. User is not defined in ELDx application';
                console.error(errorText);
                this.datadogService.log(errorText, {env: environment.prefix}, 'error');
            }
        });
        this.router.navigate(['404-page'], { queryParamsHandling: 'preserve', state: pnfData });
        return of({
            activeAccount: account
        });
    }

    private zonarUserLoginLogic(activeAccount) {
        return this.permissionsService.getZonarUser().pipe(
            filter((x) => !!x),
            map(userPolicy => this.getPolicyForApplication(userPolicy)),
            delay(1000),
            switchMap((userPolicyInfo) => forkJoin([
                of(userPolicyInfo),
                this.permissionsService.getUser().pipe(take(1), map(users => users)),
                this.permissionsService.getCurrentCompanyContext().pipe(take(1), map(company => company)), //from sidenav
                this.appService.getCompanyByAccount(activeAccount)
            ])),
            map(([zonarUserGroupPolicy, user, company, acCompany]) => {
                let companyForContext = company;
                const userPerm = {
                    roles: zonarUserGroupPolicy.roles
                };

                if (acCompany && acCompany.length > 0) {
                    //there is an active account company
                    companyForContext = acCompany[0];
                }
                const companyId = companyForContext.id;
                this.permissionsService.setCurrentCompanyContextById(companyId);
                this.changeCompanyService.changeSelectedCompany(companyId);
                this.appService.getLegacyAccountCode(companyId).subscribe((accounts) => {
                    const chosenAccount = activeAccount || accounts[0];
                    const accountMapping = accounts.map((x: string) => ({value: x, title: x}));
                   this.appService.dispatchActiveAcct(chosenAccount, accountMapping);
                   this.appService.getZonarDispatcher(chosenAccount).subscribe(
                       (zonarDispatcher) => {
                           this.appService.dispatchDispatcherEntity(zonarDispatcher);
                       }, catchError((err) => {
                           console.log('zonar dispatcher not found', err);
                           return of('');
                       }));
                });
                return this.mapToAppRouteData(activeAccount, [userPerm], user, companyForContext);
            })
        );
    }

    private determineLoginMode(company: ICompany) {
        // determine users login mode and retrieve either user profile or group policy
        if (!!company && company.loginMode === 'USER_PROFILE') {
            return this.permissionsService.getUserProfiles().pipe( //used to filter out the correct user profile
                filter((x: any[]) => !!x),
                map(profiles => this.getUserProfiles(profiles, company.id)[0]),
            );
        } else {
            return this.permissionsService.getUserGroupPolicies().pipe(
                filter((x: any[]) => !!x),
                switchMap((groupPolicies) => {
                    //logic to get the correct group policy for eldx
                    const companyId = company.id;
                    const gpWithCompany = groupPolicies.filter((gpCompany) => gpCompany.policy.companyId === companyId);
                    const gpWithPerms = [];
                    gpWithCompany.forEach((gp) => {
                        const eldxPerms = gp.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId);
                        if (eldxPerms.length > 0) {
                            gpWithPerms.push(gp);
                        }
                    });
                    return of(gpWithPerms);
                }),
                map(userPolicy => this.getPolicyForApplication(userPolicy[0])),
                catchError((err) => {
                    console.log('error at getting group policy', err);
                    const groupPolicyErr ={
                        name: 'Missing policy permissions',
                        message: 'User is missing policy permission to access this application',
                        status: 400
                    };
                    return throwError(err);
                })
            );
        }
    }

    private getCompanyLoginMode(companyId?) {
        // determine login mode user has access to based on company
        const index = 0;
        return this.permissionsService.getCompanyLoginMode().pipe(
            filter((x: any[]) => !!x), //filter out null response
            switchMap((companies) => {
                let company;
                if (companyId) {
                    //matches the users login method to the company they are trying to access
                    company = companies.find((x) => x.id === companyId);
                } else {
                    company = companies[index];
                }
                if (!company) {
                    const errorMessage = 'User not associated to company';
                    if (companyId) {
                        return throwError({
                            name: errorMessage,
                            message: `${errorMessage} with id: ${companyId}`,
                            status: 400});
                    }
                    return throwError({
                        name: errorMessage,
                        message: errorMessage,
                        status: 400});
                }
                this.permissionsService.setCurrentCompanyContextById(company.id); //sets user profile
                this.changeCompanyService.changeSelectedCompany(company.id);
                return of([company]);
            }),
        );
    }

    private determineLegacyAccounts(activeAccount: string, companyLoginWithLegacyAccounts: any[]) {
        //get the legacy accounts for companies
        let hasAccount = false;
        let companyWithActiveAccount: any;
        companyLoginWithLegacyAccounts.forEach((company) => {
            if (company.legacyAccounts.includes(activeAccount)) {
                hasAccount = true;
                companyWithActiveAccount = company;
            }
        });
        if (hasAccount && companyWithActiveAccount) {
            //found the account that has the company
            const accounts = companyWithActiveAccount.legacyAccounts.map((x: string) => ({title: x, value: x}));
            this.appService.dispatchActiveAcct(activeAccount, accounts);
            this.appService.dispatchCompanyId(companyWithActiveAccount.id);
            return of(companyWithActiveAccount);
        } else {
            const err = {
                message: `user does not have permissions to enter ${activeAccount}`,
                name: 'Insufficient permissions',
                status: 403
            };
            this.handleError(err, activeAccount);
        }
    }

    private mappingResults(
        activeAccount: string,
        userPermissionsInfo: {application: any; roles: any[]} | IUserProfile,
        user: IUser, company: ICompany){
        // userPermissionsInfo is either a User Profile or grant from group policy
        let userPerms: any;
        let companyId = company.id || undefined;
        if (userPermissionsInfo && 'application' in userPermissionsInfo) {
            //group policy
            userPerms = userPermissionsInfo;
        } else {
            //this is user profile
            userPerms = userPermissionsInfo as IUserProfile;
            this.appService.setCompany(userPerms?.companyId, userPerms);
            companyId = userPerms?.companyId;
        }
        if (companyId) {
            this.changeCompanyService.changeSelectedCompany(companyId);
            this.appService.getCompanyAccountsById(companyId).pipe(
                map((divisions) => {
                    if (divisions.length > 0) {
                        const accts = divisions.map((d) => ({title: d.value, value: d.value}));
                        const chosenAcct = activeAccount || accts[0].title;
                        this.appService.dispatchActiveAcct(chosenAcct, accts);
                        this.appService.dispatchCompanyId(companyId);
                        this.appService.getDispatcher(chosenAcct, null).pipe(
                            map((dispatcher) => {
                                this.appService.dispatchDispatcherEntity(dispatcher[0]);
                            }, catchError((err) => {
                                console.log('err here non zonar', err);
                                return of('');
                            }))
                        );
                    } else {
                        console.log('account does not have legacy accounts');
                    }
                })
            ).subscribe();
        }
        return this.mapToAppRouteData(activeAccount, [userPerms], user, company);
    }
}
