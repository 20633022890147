import {createAction, props} from '@ngrx/store';

export const setAccount = createAction(
    '[Account] SetAccount',
    props<{payload: any}>()
);

export const setAvailableAccounts = createAction(
    '[Account] setAvailableAccounts',
    props<{payload: Array<{value: string}>}>()
);

export const setDispatcherEntityId = createAction(
    '[Account] setDispatcherLegacyId',
    props<{payload: any}>()
);

export const setUserProfileEntityId = createAction(
    '[Account] setUserProfileEntityId',
    props<{payload: any}>()
);

export const setCompanyId = createAction(
    '[Account] setCompanyId',
    props<{payload: any}>()
);
