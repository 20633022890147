// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details
export const permissions = {
    defaultRedirectUrl: '/',
    defaultPermPrefix: 'hours_of_service',
    routes: [
        ['/', 'read:udl'],
        ['/no-driver-reasons', 'write:ndr'],
        ['/driver-logs', 'read:driver_logs']
    ]
};
export const appName = '';
export const appNameForPerm = 'eldx';
