import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, distinctUntilChanged} from 'rxjs/operators';
import { AppService } from './app.service';
import {
    sidenavGlobalConfig,
    sidenavHeaderConfig
} from '@app/shared/sidenav.config';
import {Store} from '@ngrx/store';
import {State as UserState} from '@app/modules/udl/state';
import {accountCodesSelector, accountSelector} from '@app/modules/udl/state/user.selectors';
import {DataDogService} from '@app/modules/services/datadog.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app-root.html',
    styleUrls: ['app.component.scss']
})
export class AppRootComponent implements OnInit, OnDestroy {
    public isLoading$!: Observable<boolean>;
    public account = undefined;
    public activeAccount = new BehaviorSubject<boolean>(false);
    public hasDefaultCompany = false;

    sidenavConfig = sidenavGlobalConfig;
    sidenavHeader = sidenavHeaderConfig;

    constructor(
        private userStore: Store<UserState>,
        private appService: AppService,
        private datadogService: DataDogService,
        private router: Router,
    ) {
        // Adding 100 ms delay to avoid flickering effect
        this.isLoading$ = this.appService.isAppDataLoading$.pipe(delay(100));
        this.userStore.select(accountCodesSelector).subscribe((x) => {
           this.account = x;
        });
    }

    ngOnInit() {
        this.datadogService.startSessionReplayRecording();
        this.userStore.select(accountSelector).pipe(distinctUntilChanged()).subscribe((acct) => {
            this.account = acct;
        });

    }

    ngOnDestroy() {
        this.datadogService.stopSessionReplayRecording();
    }

    selectCompany($event) {
        if (this.account) {
            this.appService.setCompany($event.value).subscribe(
                (x) => {
                    if (x == null) {
                        //null is the success case in setCompany
                        return;
                    }
                    if (x.error) {
                        const errMessage = {
                            title: 'User is unable to access company',
                            error: x.name,
                            errorMessage: x.message,
                            status: 400
                        };
                        this.router.navigate(['404-page'], { state: errMessage});
                    }
                });
            return;
        }
        if (window.location.pathname === '/404-page') {
            //when switch company on 404 page, attempt to reload the page
            this.router.navigate(['/'], { });
        }

    }
}
