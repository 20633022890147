import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '@zonar-ui/auth';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  private user: User;
  //todo potentionally move this into our other apps for rum and datadog config
  private datadogConfig = {
    common: {
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.datadog.service,
    },
    logs: {
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all', // Can also specify array ['log', 'debug', 'info', 'warn', 'error'],
      forwardReports: 'all', // Can also specify array ['intervention', 'deprecation', 'csp_violation']
      sampleRate: 100,
    },
    rum: {
      applicationId: environment.datadog.applicationId,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    }
  };
  constructor(private permissions: PermissionsService) {
    if (!this.isLocalDevEnv()) {
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        ...this.datadogConfig.common,
        ...this.datadogConfig.rum,
        env: environment.prefix
      });
      // @ts-ignore because it doesn't recognize 'all'
      datadogLogs.init({
        ...this.datadogConfig.common,
        ...this.datadogConfig.logs,
        env: environment.prefix,
      });

      this.permissions.getUser().pipe(first(users => Boolean(users))).subscribe(user => {
        this.user = user;
        datadogRum.setUser({
          id: this.user.id,
          email: this.user.email,
          sub: this.user.sub
        });
      });
    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (this.isLocalDevEnv()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (!this.isLocalDevEnv()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (!this.isLocalDevEnv()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (!this.isLocalDevEnv()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }

  isLocalDevEnv() {
    return environment.prefix === 'local' || environment.prefix === 'dev';
    // return false;
  }
}
