/* eslint-disable @typescript-eslint/naming-convention */
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import {
    ZonarUiHosNavigationModule,
    HOS_APP_URL,
    HOS_ENV,
    HOS_APP_APPLICATION_IDS,
    HOS_APP_NAME,
    HOS_APP_DEV_CYCLE_CLIENT_ID
} from '@zonar-ui/hos-navigation';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { AuthHttpInterceptor, AuthModule, AuthService} from '@auth0/auth0-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPendoModule } from 'ngx-pendo';
import { reducers } from './modules/udl/state';
import {appNameForPerm, permissions} from '../environments/shared';
import { AppRootComponent } from './app-root.component';
import { ZonarUiI18nModule } from '@zonar-ui/i18n';
import { TranslateModule } from '@ngx-translate/core';
import {ZonarUiHosComponentsModule} from '@zonar-ui/hos-components';
import { ZonarUiFooterModule } from '@zonar-ui/footer';
import { SidenavModule } from '@zonar-ui/sidenav';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        AppRootComponent,
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({}),
        AuthModule.forRoot({
            clientId: environment.auth.clientID,
            domain: environment.auth.domain,
            audience: environment.auth.audience,
            redirect_uri: window.location.origin,
            scope: 'openid profile email',
            useRefreshTokens: true,
            max_age: 36000,
            cacheLocation: 'localstorage',
            httpInterceptor: {
                allowedList: [
                    environment.urls.baseApi + '/*',
                    environment.urls.udlApi + '/*',
                    environment.urls.externalApi + '/*',
                    environment.urls.driverLogsApi + '/*',
                    environment.urls.legacyEntityApi + '/*',
                    environment.urls.hosReportApi + '/*',
                ]
            }
        }),
        CoreModule,
        AppRoutingModule,
        ZonarUiI18nModule,
        ZonarUICoreModule,
        ZonarUiAuthModule,
        FlexLayoutModule,
        ZonarUiProgressSpinnerModule,
        ZonarUiHosNavigationModule,
        StoreModule.forRoot(reducers, {}),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        NgxPendoModule.forRoot({
            pendoApiKey: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
            pendoIdFormatter: (value: any) => value.toString().toLowerCase()
        }),
        HttpClientModule,
        ZonarUiPermissionsModule,
        ZonarUiHosComponentsModule,
        ZonarUiFooterModule,
        SidenavModule.forRoot({stage: environment.authEnv}),
        MatIconModule
    ],
    providers: [
        AuthService,
        AuthModule,
        ZonarUiPermissionsModule,
        {provide: 'applicationId', useValue: environment.auth.applicationId},
        {provide: 'environment', useValue: environment.authEnv},
        {provide: HOS_ENV, useValue: environment.name},
        {provide: HOS_APP_NAME, useValue: appNameForPerm},
        {
            provide: HOS_APP_URL, useValue: {
                eldx: environment.appUrl,
                compliance: environment.urls.complianceUrl
            }
        },
		{
			provide: HOS_APP_APPLICATION_IDS, useValue: {
				eldx: environment.auth.applicationId,
				compliance: environment.complianceApplicationId,
			}
		},
        {
            provide: HOS_APP_DEV_CYCLE_CLIENT_ID,
            useValue: environment.devCycle.clientId
        },
        {provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},
        {provide: 'routePermissions', useValue: permissions.routes},
        {provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
        {provide: 'region', useValue: environment.region},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true},
        {provide: 'supportedLanguages', useValue: ['en-US']},
        {provide: 'defaultLanguage', useValue: 'en-US'},
        ZonarUiAuthModule
    ],
    bootstrap: [AppRootComponent],
    exports: []
})
export class AppModule { }
